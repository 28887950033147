import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { getUserInfo, getUserPermissions } from "@constants/storage";
import { AbilityContext } from "./Can";
import defineAbilityFor from "../permisions";

const AccountContext = createContext(null);

export const AccountProvider = (props) => {
    const userData = getUserInfo();
    const permissionData = getUserPermissions();
    const [userInfo, setUserInfo] = useState(userData);
    const [userPermissions, setUserPermissions] = useState(permissionData);

    const ability = useMemo(
        () => defineAbilityFor(userPermissions),
        [userPermissions]
    );

    return (
        <AccountContext.Provider
            value={{
                userInfo,
                userPermissions,
                ability,
                setUserInfo,
                setUserPermissions,
            }}
        >
            <AbilityContext.Provider value={ability}>
                {props.children}
            </AbilityContext.Provider>
        </AccountContext.Provider>
    );
};

export const useAccountContext = () => {
    const context = useContext(AccountContext);
    if (context === null) {
        throw new Error(
            "useAccountContext must be used within a AccountProvider"
        );
    }
    return context;
};
