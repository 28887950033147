import { PureAbility, AbilityBuilder } from "@casl/ability";
import _ from "lodash";

const lambdaMatcher = (matchConditions) => matchConditions;

export default function defineAbilityFor(permissions) {
    const { can, cannot, build } = new AbilityBuilder(PureAbility);
    const makeCan = (groups) => {
        if (_.isObject(groups) && Object.keys(groups).length > 0) {
            for (const group in groups) {
                const check = groups[group];
                if (_.isObject(check)) {
                    makeCan(check);
                } else {
                    if (check) {
                        can(group, "");
                    } else {
                        cannot(group, "");
                    }
                }
            }
        }
    };
    makeCan(permissions);

    return build({ conditionsMatcher: lambdaMatcher });
}

export const canSome = (ability, permissions, resource = "") => {
    try {
        if (_.isArray(permissions) && permissions.length > 0) {
            return permissions.some((perm) => {
                return ability.can(perm, resource);
            });
        }
        return ability.can(permissions, resource);
    } catch (err) {
        return false;
    }
};
