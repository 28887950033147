// ** React Imports
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeContext } from "./utility/context/ThemeColors";
import Spinner from "./@core/components/spinner/Fallback-spinner";
import "./@core/components/ripple-button";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import "antd/dist/reset.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
import { store } from "@redux/store";
import { AccountProvider } from "./utility/context/AccountContext";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import Hotjar from '@hotjar/browser';

const siteId = 4981543;
const hotjarVersion = 6;

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <Suspense fallback={<Spinner />}>
                <ThemeContext>
                    <AccountProvider>
                        <DndProvider backend={HTML5Backend}>
                            <LazyApp />
                        </DndProvider>
                    </AccountProvider>
                </ThemeContext>
            </Suspense>
        </Provider>
    </BrowserRouter>
);

serviceWorker.unregister();
Hotjar.init(siteId, hotjarVersion);
